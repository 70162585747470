import { acceptHMRUpdate, defineStore } from "pinia";
import type { HttpClient } from "~/infra/http/HttpClient";

export const useTerraInternetFixaV1Store = defineStore(
  "terra-internet-fixa-v1-store",
  {
    state: () => ({
      _id: "",
      credit_analysis_error: false,
      status: [],
      customerType: "PESSOA_FISICA",
      customer: {
        gender: "",
        cpf: "",
        name: "",
        card_number: "",
        card_agency: "",
        card_state: "",
        birth: "",
        email: "",
        phone: "",
        optional_phone_1: "",
        optional_phone_2: "",
        motherName: "",
      },
      fixed_internet: {
        sale_origin: "",
        id: "",
        coverage : false
      },
      custom_attributes: {
        good_payer: false,
      },
      due_date: undefined,
      payment: {
        type: "",
        available: {},
        obs: "",
        attachements: [],
      },
      address: {
        postcode: "",
        address: "",
        number: "",
        neighborhood: "",
        add_on: "",
        state: undefined,
        city: undefined,
      },
      type: "INTERNET_FIXA",
      speedy_customer: false,
      saleEndPayload: {},
      voip: {
        line_number: "",
        owner_name: "",
      },
      play: {},
    }),
    actions: {
      setFirstStepPayload(payload: any) {
        console.log("payload", payload);
        console.log("this.custom_attributes ", this.custom_attributes);
        this.customer = {
          ...payload.customer,
        };
        this.address = {
          ...payload.address,
        };
        this.custom_attributes = {
          good_payer: payload.custom_attributes.good_payer,
        };
        this.fixed_internet.sale_origin = payload.sale_origin;
        this.customerType = payload.customerType;
        this.voip.line_number = payload.line_number;
        this.voip.owner_name = payload.owner_name;
      },
      setPlans(payload: any) {
        this.fixed_internet = {
          ...this.fixed_internet,
          ...payload,
        };
      },
      setCustomizeInvoiceStepPayload(payload: any) {
        this.payment = {
          ...this.payment,
          ...payload,
        };
        this.due_date = payload.due_date;
      },
      setSaleEndPayload(payload: any) {
        this.saleEndPayload = payload;
      },

      addSaleIdFromDraft(_id: string) {
        this._id = _id;
      },

      addSaleStatusFromDraft(status: any) {
        this.status = status;
      },

      async sendSaleToDraft(step: number, axios: HttpClient) {
        console.log("payload", this.payloadSale);
        return await axios.post("sales/draft", {
          sale: {
            ...this.payloadSale,
            step: step,
            type: "INTERNET_FIXA",
            _id: this._id,
          },
        });
      },

      async sendCanceledSale(
        step: number,
        axios: HttpClient,
        status = "ABANDONADO",
        substatus = "CREDITO_NEGADO",
      ) {
        return await axios.post("sales/cancel", {
          sale: {
            ...this.payloadSale,
            step: step,
            type: "INTERNET_FIXA",
            _id: this._id,
            status: {
              type: status,
              substatus: substatus,
            },
          },
        });
      },
    },

    getters: {
      payloadSale(): any {
        return {
          credit_analysis_error: this.credit_analysis_error ,  
          customer: {
            ...this.customer,
            cpf: this.customer.cpf?.replace(/[^\d]+/g, "") || "",
            phone: "+55".concat(this.customer.phone?.replace(/[^\d]+/g, "")),
            optional_phone_1: "+55".concat(
              this.customer.optional_phone_1?.replace(/[^\d]+/g, ""),
            ),
            optional_phone_2: "+55".concat(
              this.customer.optional_phone_2?.replace(/[^\d]+/g, ""),
            ),
            addresses: [this.address],
          },
          ...(this.custom_attributes.good_payer && {
            custom_attributes: this.custom_attributes,
          }),
          fixed_internet: {
            provider: {
              name: "Terra",
              slug: "terra",
            },
            ...(this.fixed_internet?.fixed_internet || {}),
          },
          payment: this.payment,
        };
      },
    },
    persist: {
      storage: persistedState.sessionStorage,
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTerraInternetFixaV1Store, import.meta.hot),
  );
}
